import { m } from 'framer-motion';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import FormProvider from "src/components/hook-form/form-provider";
import { Form, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { fToNow } from 'src/utils/format-time';

import { _contacts } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect, useState } from 'react';
import { AccountService, ProductService } from 'mios-direct-client-module/client-web';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { RHFAutocomplete, RHFMultiSelect, RHFTextField, RHFUploadAvatar, RHFUploadBox } from "src/components/hook-form";
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const popover = usePopover();

  const { user, currentProject } = useAuthContext();

  const [members, setMembers] = useState([]);
  const [openModel, setOpenModal] = useState(false);
  const [openModalAddMember, setOpenModalAddMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    console.warn('ContactsPopover useEffect', currentProject);
    if (currentProject?.id_product) {
      AccountService.getUserFromProduct(currentProject?.id_product).then((res) => {
        setMembers(res.data);
      }).catch((err) => {
        console.error(err);
      }).finally(() => {

      });
    }
  }, [currentProject]);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    data.id_product = currentProject?.id_product;
    AccountService.addUserInProduct(data.email, data.id_product).then((res) => {
      enqueueSnackbar('Membro adicionado ou convite enviado com sucesso', { variant: 'success' });
      window.location.reload();
    }).catch((err) => {
      console.error(err);
      enqueueSnackbar('Erro ao adicionar membro', { variant: 'error' });
    }).finally(() => {
      setOpenModalAddMember(false);
    });
  });

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Iconify icon="solar:users-group-rounded-bold-duotone" width={24} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 420 }}>
        <Stack direction="row" justifyContent="start" spacing={2} sx={{ mt: 2 }} alignItems="center">
          <Typography variant="h6" sx={{ p: 1.5 }} flex={1}>
            Membros <Typography component="span">({members.length})</Typography>
          </Typography>
          <Button
            onClick={() => {
              setOpenModalAddMember(true)
            }}
            variant="contained" size="small">
            Adicionar membro
          </Button>
        </Stack>

        <Scrollbar sx={{ height: 320 }}>
          {members.map((contact) => (
            <MenuItem key={contact.id} sx={{ p: 1 }}>
              <Badge
                variant={contact.status}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ mr: 2 }}
              >
                <Avatar alt={contact.first_name} src="https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg" />
              </Badge>

              {/* <ListItemText
                primary={contact.first_name}
                secondary={contact.status === 'offline' ? fToNow(contact.lastActivity) : ''}
                primaryTypographyProps={{ typography: 'subtitle2' }}
                secondaryTypographyProps={{
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              /> */}
              <Stack direction="row" width="100%">
                <Stack direction="column" alignItems="start" flex={1} >
                  <Typography variant="subtitle2">{contact?.first_name}</Typography>
                  <Typography variant="caption" color="text.disabled">{contact?.email}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" >
                  <Button
                    onClick={() => {
                      setSelectedMember(contact);
                      setOpenModal(true);
                    }}
                    disabled={contact?.rule_access === "ROOT"}
                    style={{ opacity: contact?.rule_access === "ROOT" ? 0 : 1 }}
                    variant="contained" color="error" size="small">
                    {
                      contact?._id.$oid === user?._id.$oid ? 'Sair' : 'Remover'
                    }
                  </Button>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </Scrollbar>
        <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }} alignItems="center">
        </Stack>
      </CustomPopover >

      <Dialog
        open={openModel}
        onClose={() => setOpenModal(false)}
      // PaperProps={{
      //   component: 'form',
      //   onSubmit: handleSubmit,
      // }}
      >
        <DialogTitle>Remover membro</DialogTitle>
        <DialogContent>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Typography variant="h7">Tem certeza que deseja remover esse membro?</Typography>
            <Typography variant="h7" fontWeight={700}>{selectedMember?.email}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2, marginTop: 5, marginBottom: 5 }}>
            <Button variant="contained" onClick={() => setOpenModal(false)}>Não</Button>

            <Button variant="contained" color="error" onClick={() => {
              AccountService.deleteUserInProduct(selectedMember?.email, currentProject?.id_product).then((res) => {
                console.warn('removeUserFromProduct', res);
                enqueueSnackbar('Membro removido com sucesso', { variant: 'success' });
                setOpenModal(false);
                window.location.reload();
              }).catch((err) => {
                console.error(err);
                enqueueSnackbar('Erro ao remover membro', { variant: 'error' });
              }).finally(() => {
                setSelectedMember(null);
              });
            }}>Sim</Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalAddMember}
        onClose={() => setOpenModalAddMember(false)}
      // PaperProps={{
      //   component: 'form',
      //   onSubmit: handleSubmit,
      // }}
      >
        <DialogTitle>Adicionar membro</DialogTitle>
        <DialogContent >
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Typography variant="h7">
              Digite o e-mail do membro que deseja adicionar ao projeto.
              <br />
              <br />
              <Typography variant='h7'>Se o e-mail digitado não estiver cadastrado no MIAI, será enviado um e-mail convidando-o a se cadastrar.</Typography>
            </Typography>
          </Stack>
          <FormProvider methods={methods} onSubmit={onSubmit} >
            <Stack direction="column" alignItems="flex-end" justifyContent="center" spacing={2} marginBottom={2} marginTop={2}>
              <RHFTextField
                fullWidth
                type="text"
                margin="dense"
                variant="outlined"
                label="Email"
                name="email"
              />
              <LoadingButton loading={isSubmitting} variant="contained" color="primary" type="submit">Adicionar</LoadingButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>

    </>
  );
}
