import { Button, Card, CardActionArea, CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { Form, useForm } from "react-hook-form";
import SeoIllustration from "src/assets/illustrations/seo-illustration";
import { AuthContext } from "src/auth/context/jwt";
import FormProvider from "src/components/hook-form/form-provider";
import AppWelcome from "src/sections/overview/app/app-welcome";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadAvatar } from "src/components/upload";
import { RHFAutocomplete, RHFMultiSelect, RHFTextField, RHFUploadAvatar, RHFUploadBox } from "src/components/hook-form";
import { fData } from "src/utils/format-number";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { AccountService, OpenAPI, ProductService } from "mios-direct-client-module/client-web"
import { useRouter } from "src/routes/hooks";
import Header from "src/layouts/dashboard/header";
import { enqueueSnackbar } from "notistack";

export default function HomeDashboardPage() {

  const { user, currentProject, setCurrentProject } = useContext(AuthContext);
  console.warn('value', user);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogPlan, setOpenDialogPlan] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const image = React.useRef(null);
  const [progressBar, setProgressBar] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);

  const [loadingBasic, setLoadingBasic] = React.useState(false);
  const [loadingPro, setLoadingPro] = React.useState(false);
  const [loadingPower, setLoadingPower] = React.useState(false);
  const [loadingSuperpower, setLoadingSuperpower] = React.useState(false);

  const router = useRouter();

  const RegisterSchema = Yup.object().shape({
    image: Yup.mixed().required('Logo da empresa é obrigatória'),
    name: Yup.string().required('Nome é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
    services: Yup.array().required('Serviços é obrigatório').length(1, 'Selecione 1 serviço'),
    site: Yup.string().required('Site é obrigatório').url('Site deve ser uma URL válida'),
  });

  const defaultValues = {
    image: null,
    name: '',
    description: '',
    services: [],
    site: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    data.image = imageUrl;
    data.id_account = user?._id.$oid;
    data.id_account_creator = user?._id.$oid;
    data.email_account_creator = user?.email;
    console.log('DATA', data);
    await ProductService.createProduct(data);
    setOpenDialog(false);
    window.location.reload();
  });

  const handleUpload = useCallback(async () => {
    const formData = new FormData();

    formData.append("file", image.current?.file);

    try {
      setIsUploading(true);
      const response = await axios.post('https://cursiverso.azurewebsites.net/image/add-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );
          if (totalLength !== null) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            console.warn('progress', progress);
            setProgressBar(progress);
          }
        },
      }).finally(() => {
        setProgressBar(0);
      });
      console.log('Upload successful:', response.data);
      setImageUrl(response.data.files[0].url);
      setValue("image", response.data.files[0].url);
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsUploading(false);
    }
  }, [setValue]);

  const handleDrop = React.useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log('file', file);

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        image.current = {
          file,
          name: file.path,
          path: file.path,
          preview: URL.createObjectURL(file),
        };
        setValue("image", newFile.preview);
        handleUpload();
      }
    },
    [handleUpload, setValue]
  );



  return (
    <Stack padding={2} spacing={2}>
      <Header showProject={false} />
      <br />
      <br />
      <br />

      {/* <Typography variant="h4">MIAI</Typography> */}
      {
        user?.products?.length > 0 ? (
          <>

            <Stack sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',
              }
            }}>
              <Stack sx={2}>
                <AppWelcome
                  title={`Seja bem-vindo 👋 \n ${user?.first_name}`}
                  description="Você já tem empresa criada, mas fique a vontade para criar quantas empresas precisar."
                  img={<SeoIllustration />}
                  action={
                    <Button disabled={!user?.plan} onClick={() => setOpenDialog(true)} variant="contained" color="primary">
                      Criar uma nova empresa
                    </Button>
                  }
                />
              </Stack>
            </Stack>
            {
              !user?.plan && (
                <Stack bgcolor="#F1E702" borderRadius={1} minHeight={50} justifyContent="center" style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenDialogPlan(true);
                  }}
                >
                  <Typography variant="body1" textAlign="center" color="black">
                    Para criar empresas, você precisa adquirir um plano. Aproveite 7 dias grátis.
                  </Typography>
                </Stack>
              )
            }
            <Grid container spacing={2} >
              {user?.products?.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                  <Card sx={{ maxWidth: 200, height: "auto", cursor: "pointer" }}
                    onClick={() => {
                      console.log('product', product);
                      setCurrentProject(product);
                      router.push(`/dashboard`);
                    }}
                  >

                    <CardMedia
                      component="img"
                      height="140"
                      image={product?.image}
                      alt="Logo da empresa"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {product?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {product?.description}
                      </Typography>
                    </CardContent>

                    <CardActionArea style={{ height: 50 }}>
                      <Stack spacing={2} style={{ width: 200, marginTop: 5 }}>
                        <Button
                          variant="contained"
                          // onClick={async () => {
                          //   console.log('product', currentProject);
                          //   await setCurrentProject(product);
                          //   router.push(`/dashboard`);
                          // }} 
                          color="primary">
                          Acessar
                        </Button>
                      </Stack>
                    </CardActionArea>

                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Stack sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',
            }
          }}>
            <Stack sx={2}>
              <AppWelcome
                title={`Seja bem-vindo 👋 \n ${user?.first_name}`}
                description="Para você poder começar a usar a plataforma, você precisa criar uma empresa."
                img={<SeoIllustration />}
                action={
                  <Button disabled={!user?.plan} onClick={() => setOpenDialog(true)} variant="contained" color="primary">
                    Criar empresa
                  </Button>
                }
              />
            </Stack>
          </Stack>
        )
      }
      {
              !user?.plan && (
                <Stack bgcolor="#F1E702" borderRadius={1} minHeight={50} justifyContent="center" style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenDialogPlan(true);
                  }}
                >
                  <Typography variant="body1" textAlign="center" color="black">
                    Para criar empresas, você precisa adquirir um plano. Aproveite 7 dias grátis.
                  </Typography>
                </Stack>
              )
            }
      <Dialog open={openDialog}>
        <DialogTitle>Crie sua empresa</DialogTitle>

        <DialogContent>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <RHFUploadAvatar
              name="image"
              accept="image/*"
              file={image.current?.preview}
              maxSize={10485760}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Extensões permitidas *.jpeg, *.jpg, *.png
                  <br /> tamanho max de {fData(10485760)}
                </Typography>
              }
            />
            {
              progressBar > 0 && (
                <LinearProgress variant="determinate" value={progressBar} />
              )
            }
            <RHFTextField
              autoFocus
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              label="Nome da empresa"
              name="name"
            />
            <RHFTextField
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              label="Descrição suas atividades"
              multiline
              maxRows={3}
              name="description"
            />
            <RHFAutocomplete
              name="services"
              label="Área de atuação"
              placeholder="Escolha um ou mais serviços"
              multiple
              freeSolo
              options={[
                "Educação",
                "Marketing",
                "Desenvolvimento",
                "Design",
                "Consultoria",
                "Vendas",
                "Outros",
              ]}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size="small"
                    color="info"
                    variant="soft"
                  />
                ))
              }
            />
            <RHFTextField
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              label="Site"
              name="site"
            />
            <Stack direction="row" spacing={2} justifyContent="end" marginTop={2} marginBottom={2}>
              <Button variant="outlined" color="inherit" onClick={() => {
                setOpenDialog(false);
                image.current = null;
                setImageUrl(null);
                methods.reset(defaultValues);
              }}>
                Cancelar
              </Button>
              <LoadingButton
                fullWidth
                color="info"
                size="large"
                type="submit"
                variant="soft"
                style={{ width: 120 }}
                loading={isSubmitting}
              >
                Criar
              </LoadingButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialogPlan}
        maxWidth="lg"
        fullWidth
        onClose={() => setOpenDialogPlan(false)}
      >
        <DialogTitle>Planos</DialogTitle>

        <DialogContent >

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack style={{ height: 50 }}>
              <Typography variant="body1" textAlign="center">
                Aproveite 7 dias grátis.
              </Typography>
            </Stack>

            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>

                  <Stack
                    component={Paper} variant='outlined' sx={{
                      p: 1,
                      minWidth: 260,
                      flexShrink: 0,
                      borderRadius: 2,
                      typography: 'body2',
                      borderStyle: 'dashed',
                      marginBottom: 1,
                      marginTop: 1,
                    }} >
                    <Typography variant="h6" textAlign="center">
                      Basic
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      1 Empresa
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      2 Usuários
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Dashboard de métricas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      MIA - Inteligência Artificial de Vendas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      500 Conversas WhatsApp
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      10 Modelos de Campanhas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Análise de SEO Detalhada
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Benchmarketing
                    </Typography>

                    <Typography variant="body1" textAlign="center">
                      <LoadingButton
                        loading={loadingBasic}
                        style={{ width: "70%" }}
                        variant="contained"
                        onClick={async () => {
                          setLoadingBasic(true);
                          AccountService.linkPayment("Basic - Direct", user?.email).then((response) => {
                            window.open(response.data, '_blank');
                          }).catch((error) => {
                            enqueueSnackbar("Erro ao gerar pagamento", { variant: 'error' });
                          }).finally(() => {
                            setLoadingBasic(false);
                          });
                        }} color="primary">
                        R$ 299,00 / mês
                      </LoadingButton>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} >

                  <Stack
                    component={Paper} variant='outlined' sx={{
                      p: 1,
                      minWidth: 260,
                      flexShrink: 0,
                      borderRadius: 2,
                      typography: 'body2',
                      borderStyle: 'groove',
                      borderColor: 'primary.main',
                      marginBottom: 1,
                      marginTop: 1,
                    }} >
                    <Stack position="relative">
                      <Typography variant="h6" textAlign="center">
                        Pro
                      </Typography>
                      <Typography variant="h6" textAlign="center" position="absolute" right={10} color="primary.main">
                        Mais popular
                      </Typography>
                    </Stack>
                    <Typography variant="body1" textAlign="center">
                      3 Projeto
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      5 Usuários
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Dashboard de métricas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      MIA - Inteligência Artificial de Vendas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      1200 Conversas WhatsApp
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      20 Modelos de Campanhas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Análise de SEO Detalhada
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Benchmarketing
                    </Typography>

                    <Typography variant="body1" textAlign="center">
                      <LoadingButton
                        loading={loadingPro}
                        style={{ width: "70%" }}
                        variant="contained"
                        onClick={async () => {
                          setLoadingPro(true);
                          AccountService.linkPayment("Pro - Direct", user?.email).then((response) => {
                            window.open(response.data, '_blank');
                          }).catch((error) => {
                            enqueueSnackbar("Erro ao gerar pagamento", { variant: 'error' });
                          }).finally(() => {
                            setLoadingPro(false);
                          });
                        }} color="primary">
                        R$ 690,00 / mês
                      </LoadingButton>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} >

                  <Stack
                    component={Paper} variant='outlined' sx={{
                      p: 1,
                      minWidth: 260,
                      flexShrink: 0,
                      borderRadius: 2,
                      typography: 'body2',
                      borderStyle: 'dashed',
                      marginBottom: 1,
                      marginTop: 1,
                    }} >
                    <Typography variant="h6" textAlign="center">
                      Power
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      8 Projeto
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      10 Usuários
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Dashboard de métricas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      MIA - Inteligência Artificial de Vendas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      2500 Conversas WhatsApp
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      30 Modelos de Campanhas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Análise de SEO Detalhada
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Benchmarketing
                    </Typography>

                    <Typography variant="body1" textAlign="center">
                      <LoadingButton
                        loading={loadingPower}
                        style={{ width: "70%" }}
                        variant="contained"
                        onClick={async () => {
                          setLoadingPower(true);
                          AccountService.linkPayment("Power - Direct", user?.email).then((response) => {
                            window.open(response.data, '_blank');
                          }).catch((error) => {
                            enqueueSnackbar("Erro ao gerar pagamento", { variant: 'error' });
                          }).finally(() => {
                            setLoadingPower(false);
                          });
                        }} color="primary">
                        R$ 1190,00 / mês
                      </LoadingButton>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} >

                  <Stack
                    component={Paper} variant='outlined' sx={{
                      p: 1,
                      minWidth: 260,
                      flexShrink: 0,
                      borderRadius: 2,
                      typography: 'body2',
                      borderStyle: 'dashed',
                      marginBottom: 1,
                      marginTop: 1,
                    }} >
                    <Typography variant="h6" textAlign="center">
                      Superpower
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      10 Projeto
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      25 Usuários
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Dashboard de métricas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      MIA - Inteligência Artificial de Vendas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      5000 Conversas WhatsApp
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      50 Modelos de Campanhas
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Análise de SEO Detalhada
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Benchmarketing
                    </Typography>

                    <Typography variant="body1" textAlign="center">
                      <LoadingButton
                        loading={loadingSuperpower}
                        style={{ width: "70%" }}
                        variant="contained"
                        onClick={async () => {
                          setLoadingSuperpower(true);
                          AccountService.linkPayment("Super Power - Direct", user?.email).then((response) => {
                            window.open(response.data, '_blank');
                          }).catch((error) => {
                            enqueueSnackbar("Erro ao gerar pagamento", { variant: 'error' });
                          }).finally(() => {
                            setLoadingSuperpower(false);
                          });
                        }} color="primary">
                        R$ 3200,00 / mês
                      </LoadingButton>
                    </Typography>
                  </Stack>
                </Grid>

              </Grid>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="end" marginTop={2} marginBottom={2}>
              <Button variant="outlined" color="inherit" onClick={() => {
                setOpenDialogPlan(false);
                image.current = null;
                setImageUrl(null);
                methods.reset(defaultValues);
              }}>
                Cancelar
              </Button>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
