import React, { forwardRef } from "react"
import "./iphoneComponent.css"
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';


export default function IphoneComponent({ url, title, subtitle, foot, buttonToAction, titleButtonToAction, stopMarketing }) {
    return (
        <div className="demo" >
            <div className="demo__phone">
                <div className="demo__screen-wrapper">
                    <div className="demo__screen">
                        <div className="demo__indicator demo__indicator--time">16:22</div>
                        <div className="demo__indicator demo__indicator--signal">
                            <i className="fa fa-signal"></i>
                        </div>
                        <div className="demo__indicator demo__indicator--battery">
                            <i className="fa fa-battery"></i>
                        </div>
                        <div className="demo__controls">
                            <div className="demo__arrow">
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <div className="demo__caption">Whatsapp</div>
                        </div>

                        <div className="demo__card demo__card demo__card--notched">
                            <div className="demo__card-frontside">
                                <div className="demo__card-type">PAYMENT</div>
                                <div className="demo__card-body">
                                    <div className="demo__card-picture demo__card-picture--apple"></div>
                                    <div className="demo__card-info">
                                        <div className="demo__card-caption">Apple store</div>
                                        <div className="demo__card-description">12 Manchester st., London</div>
                                    </div>
                                    <div className="demo__card-money">-$999</div>
                                </div>
                                <div className="demo__card-bottom">
                                    <div className="demo__card-button">View balance</div>
                                    <div className="demo__card-time">5m ago</div>
                                </div>
                            </div>
                            <div className="demo__card-backside">
                                <div className="demo__card-release">
                                    release
                                </div>
                            </div>
                        </div>
                        <div className="demo__card demo__card--regular">
                            <div style={{ backgroundColor: "white", width: "100%", height: "auto", minHeight: 150, borderRadius: 10, padding: 5, gap: 10, display: "flex", flexDirection: "column" }}>
                                <div style={{ borderRadius: 6, overflow: "hidden", minHeight: 150, maxHeight: 150 }}>
                                    {/* <video src={url} alt="Imagem" style={{ width: "98%", height: 150, margin: "auto", display: "block", objectFit: "cover" }} /> */}
                                    {
                                        url && (
                                            <img src={url} alt="Imagem" style={{ width: "98%", minHeight: 150, maxHeight: 150, margin: "auto", display: "block", objectFit: "cover" }} />
                                        )
                                    }
                                </div>
                                {/* <div style={{ width: "94%", alignSelf: "center" }}>
                                    <Typography.Text style={{ fontSize: 12, textAlign: "start", fontWeight: 700 }}>{title}</Typography.Text>
                                </div> */}
                                <div style={{ width: "94%", alignSelf: "center" }}>
                                    <Typography style={{ fontSize: 12, textAlign: "start", color: "black" }}>{subtitle}</Typography>
                                </div>
                                <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "space-between", display: "flex" }}>
                                    <Typography style={{ fontSize: 12, textAlign: "start", color: "black" }}>{foot}</Typography>
                                    {
                                        url && (
                                            <div style={{ minWidth: 40, display: "flex", justifyContent: "center", alignItems: "end" }}>
                                                <Typography style={{ fontSize: 12, textAlign: "start", color: "black" }}>19:00</Typography>
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    buttonToAction && (
                                        <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "center", display: "flex" }}>
                                            <div className="button-action">
                                                <Typography style={{ fontSize: 12, textAlign: "start", color: "green", fontWeight: 700 }}>{titleButtonToAction}</Typography>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    stopMarketing && subtitle && (
                                        <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "center", display: "flex" }}>
                                            <div className="button-action">
                                                <Typography style={{ fontSize: 12, textAlign: "start", color: "green", fontWeight: 700 }}>Parar promoções</Typography>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="demo__notch"></div>
            </div>
        </div>
    )
}

IphoneComponent.propTypes = {
    url: PropTypes?.string,
    title: PropTypes?.string,
    subtitle: PropTypes?.string,
    foot: PropTypes?.string,
    buttonToAction: PropTypes?.bool,
    titleButtonToAction: PropTypes?.string,
    stopMarketing: PropTypes?.bool
}