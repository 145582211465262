// Function to detect the key that is most likely to contain a name in an object
export function detectNameKey(obj) {
    // Lista expandida de indicadores de nomes
    const nameIndicators = ['nome', 'name', 'firstname', 'lastname', 'surname', 'givenname', 'familyname'];

    let likelyKey = null;
    let highestScore = 0;

    Object.keys(obj).forEach(key => {
        let score = 0;
        // Remove caracteres indesejados e converte para minúsculas
        const keyLower = key.toLowerCase().replace(/[\r\n\t]/g, '');

        // Incrementa a pontuação para cada indicador encontrado na chave
        nameIndicators.forEach(indicator => {
            if (keyLower.includes(indicator)) {
                // Pontuação maior se o indicador estiver no início da chave
                score += (keyLower.indexOf(indicator) === 0) ? 2 : 1;
            }
        });

        // Atualiza a pontuação mais alta e a chave mais provável
        if (score > highestScore) {
            highestScore = score;
            likelyKey = key;
        }
    });

    return likelyKey;
}

export function detectPhoneKey(obj) {
    // Lista de indicadores de telefone, sem duplicatas
    const phoneIndicators = ['phone', 'tel', 'mobile', 'celular', 'whatsapp', 'zap', 'telefone', 'contato', 'whats', 'contact', 'cel'];

    let likelyKey = null;
    let highestScore = 0;

    Object.keys(obj).forEach(key => {
        let score = 0;
        // Remove caracteres indesejados e converte para minúsculas
        const keyLower = key.toLowerCase().replace(/[\r\n\t]/g, '');

        // Incrementa a pontuação para cada indicador encontrado na chave
        phoneIndicators.forEach(indicator => {
            if (keyLower.includes(indicator)) {
                // Pontuação maior se o indicador estiver no início da chave
                score += (keyLower.indexOf(indicator) === 0) ? 2 : 1;
            }
        });

        // Atualiza a pontuação mais alta e a chave mais provável
        if (score > highestScore) {
            highestScore = score;
            likelyKey = key;
        }
    });

    return likelyKey;
}