import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { detectNameKey, detectPhoneKey } from 'src/utils/detect-keys';

import { useResponsive } from 'src/hooks/use-responsive';
import { useAuthContext } from 'src/auth/hooks';
import { isAfter, isBetween } from 'src/utils/format-time';
import { useSnackbar } from 'notistack';
import { ensureInternationalFormat } from 'src/utils/ensure-ddi';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Card, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Step, StepLabel, Stepper } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { RHFAutocomplete, RHFTextField, RHFUpload, RHFUploadAvatar, RHFUploadBox } from 'src/components/hook-form';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormProvider from 'src/components/hook-form/form-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import IphoneComponent from 'src/components/iphoneComponent/iphoneComponent';
import IphoneComponentOnlyCard from 'src/components/iphoneComponentOnlyCard/iphoneComponentOnlyCard';
import { BagyService, TemplateService, WhatsappMessageService } from 'mios-direct-client-module/client-web';
import { width } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useRouter } from 'src/routes/hooks';
import { UploadBox } from 'src/components/upload';
import axios from 'axios';
import { fData } from 'src/utils/format-number';
import { csvToJson } from 'src/utils/convert-csv-json';
import BankingInviteFriends from '../overview/banking/banking-invite-friends';

export default function BulkMessageView() {
    const { enqueueSnackbar } = useSnackbar();

    const { authenticated, method, currentProject, user, setCurrentProject, reloadInfoUserAndProject } = useAuthContext();
    const router = useRouter();

    const theme = useTheme();
    const settings = useSettingsContext();
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    const [selectedPartner, setSelectedPartner] = useState(null);

    const [columnsGrid, setColumnsGrid] = useState([]);
    const [rowsGrid, setRowsGrid] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [completeTemplate, setCompleteTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [progressBarSize, setProgressBarSize] = useState(0);

    const [openMotalMicrosoft, setOpenModalMicrosoft] = useState(false);
    const fileMicrosoft = useRef(null);

    const [openModalSendBulkMessage, setOpenModalSendBulkMessage] = useState(false);

    const progressBarSizeRef = React.useRef(progressBarSize);

    function getColumnsGrid(key) {
        if (key === 'email') {
            return {
                field: key,
                headerName: "Email",
                flex: 1,
                minWidth: 150,
                type: 'string',
            }
        }
        if (key === 'name') {
            return {
                field: key,
                headerName: "Nome",
                flex: 1,
                minWidth: 150,
                type: 'string',
            }
        }

        if (key === 'gender') {
            return {
                field: key,
                headerName: "Gênero",
                flex: 1,
                minWidth: 150,
                type: 'string',
            }
        }

        if (key === 'phone') {
            return {
                field: key,
                headerName: "Telefone",
                flex: 1,
                minWidth: 150,
                type: 'string',
            }
        }

        if (key === 'orders_count') {
            return {
                field: key,
                headerName: "Compras",
                type: 'number',
                minWidth: 150,
            }
        }

        if (key === "created_at") {
            return {
                field: key,
                headerName: "Data",
                type: 'string',
                minWidth: 150,
                filterable: false
            }
        }

        return {
            field: key,
            headerName: key,
            flex: 1,
            minWidth: 150,
            type: 'string',
        }
    }

    useEffect(() => {
        TemplateService.returnAllTemplatesByProject(currentProject?._id?.$oid).then((response) => {
            setCompleteTemplate(response.data);
            const dataTemplates = []
            response.data.forEach(element => {
                dataTemplates.push(element.name);
            });
            setTemplates(dataTemplates);
        });
    }, [currentProject]);

    useEffect(() => {
        if (selectedPartner?.id_partner === "bagy") {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoxNTAyNjUsInR5cGUiOiJhcGkiLCJlbWFpbCI6Ijg0ODM4NzAyODgzNjA4NDAwMC5iYWd5QGFwaS5jb20uYnIiLCJmaXJzdF9uYW1lIjoiTWFydHoiLCJhY3RpdmUiOnRydWUsImlhdCI6MTcxNTAyOTUxMX0.YvF2ebklniS5h8Bs5IRvyx-4XiZRMwtNHSBtgTATtrQ");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            BagyService.returnAllClientsFromBagy(currentProject?._id?.$oid).then((response) => {
                setRowsGrid(response.data);

                const columns = Object.keys(response.data[0]).map(key => (getColumnsGrid(key)));

                setColumnsGrid(columns);
            }).catch((error) => {
                setRowsGrid([]);
                enqueueSnackbar("Erro ao buscar dados do Bagy", { variant: 'error', autoHideDuration: 10000 });
            });
        }
    }, [selectedPartner, currentProject, enqueueSnackbar]);

    const listPartners = [
        {
            id_partner: "microsoft",
            name: "csv, xlsx, xls",
            image: "https://logos-world.net/wp-content/uploads/2022/02/Microsoft-Excel-Emblem.png"
        },
        {
            id_partner: "bagy",
            name: "Bagy",
            image: "https://cursiversostorage.blob.core.windows.net/miosacademyimages/501c6c6c-8108-475b-9c3e-5aecb75c8704.jpeg"
        }
    ]

    const applyFilter = (rows) => {
        if (!filterModel) {
            return rows;
        }

        const operators = filterModel.items;

        const filteredRows = rows.filter((row) =>
            operators.every((operator) => {
                const rowValue = row[operator.field];
                const filterValue = operator.value;

                switch (operator.operator) {
                    case '>':
                        if (operator.field === "orders_count") {
                            return parseFloat(rowValue) > parseFloat(filterValue);
                        }
                        return parseFloat(rowValue) > parseFloat(filterValue);
                    case '<':
                        if (operator.field === "orders_count") {
                            return parseFloat(rowValue) < parseFloat(filterValue);
                        }
                        return parseFloat(rowValue) < parseFloat(filterValue);
                    case '=':
                        if (operator.field === "orders_count") {
                            return parseFloat(rowValue) === parseFloat(filterValue);
                        }
                        return rowValue === filterValue;
                    case 'contains':
                        return rowValue && rowValue.toString().includes(filterValue);
                    default:
                        return true;
                }
            })
        );
        return filteredRows;
    };


    const [filterModel, setFilterModel] = useState(undefined);

    const RegisterSchema = Yup.object().shape({
        select_account: Yup.string().required('Precisa selecionar uma conta'),
        select_template: Yup.string().required('Precisa selecionar um template'),
        name_campaing: Yup.string().required('Precisa informar o nome da campanha'),
        select_where_phone: Yup.string().required('Precisa selecionar o campo que contém o telefone'),
    });

    const defaultValues = {
        select_account: '',
        select_template: '',
        name_campaing: '',
        select_where_name: '',
        select_where_phone: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        console.log('data', data);
        setOpenModalSendBulkMessage(false);
        fetchData();
    });

    const fetchData = async () => {
        setLoadingRequest(true);
        const templateFilter = completeTemplate.find(f => f.name === methods.watch("select_template", ""));
        try {
            const urls = [];

            applyFilter(rowsGrid).forEach(element => {

                const detectNameKeyValue = detectNameKey(element);
                console.log("detectNameKeyValue", detectNameKeyValue)
                const detectPhoneKeyValue = detectPhoneKey(element);
                console.log("detectPhoneKeyValue", detectPhoneKeyValue)

                const displayPhoneNumber = element[detectPhoneKeyValue];
                const name = element[detectNameKeyValue];

                console.log("name", name)

                const payloadData = {
                    id_message: currentProject?.wa_business_id,
                    wa_id: displayPhoneNumber,
                    wam_id: "",
                    wa_profile_name: name,
                    phone_number_id: currentProject?.phones_number?.find(f => f.phone_number === methods.watch("select_account"))?.phone_number_id,
                    message_product: "whatsapp",
                    display_phone_number: displayPhoneNumber,
                    profile_name: "Mios",
                    from_phone_number: methods.watch("select_account", ""),
                    date_message: new Date(),
                    type_message: "template",
                    body_message: methods.watch("select_template", ""),
                    video_message: "",
                    image_message: templateFilter?.components[0]?.example,
                    document_message: "",
                    audio_message: "",
                    caption_message: "",
                    status_message: "",
                    template_name: methods.watch("select_template", ""),
                    template_type: "template",
                    marketing_campaign: methods.watch("name_campaing", ""),
                }

                console.log("payloadData", payloadData)

                urls.push({
                    payload: payloadData
                });
            });

            console.log('urls', urls);

            const requestsService = []

            urls.forEach(url => {
                requestsService.push(WhatsappMessageService.sendMessageTemplate(url.payload).then((res) => {
                    enqueueSnackbar("Mensagem enviada com sucesso", { variant: 'success', autoHideDuration: 8000 });
                }).catch((error) => {
                    enqueueSnackbar(`Erro ao enviar mensagem para ${url?.payload?.wa_id}`, { variant: 'error', autoHideDuration: 8000 });
                }));
            })

            const results = await Promise.all(requestsService);
            console.log('results', results);
        } catch (error) {
            console.error('Error sending messages:', error);
        } finally {
            setLoadingRequest(false);
            setProgressBarSize(0);
            progressBarSizeRef.current = 0;
            enqueueSnackbar("Envio de mensagem em massa finalizado", { variant: 'info', autoHideDuration: 3000 });
        }
    };

    const handleUpload = useCallback(async () => {
        const formData = new FormData();

        formData.append("file", fileMicrosoft.current?.file);

        try {
            // setIsUploading(true);
            const response = await axios.post('https://cursiverso.azurewebsites.net/image/add-csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') ||
                        progressEvent.target.getResponseHeader(
                            'x-decompressed-content-length'
                        );
                    if (totalLength !== null) {
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        );
                        console.warn('progress', progress);
                        // setProgressBar(progress);
                    }
                },
            }).finally(() => {
                // setProgressBar(0);
            });

            // Fetch the CSV file from the URL
            const ff = await fetch(response.data.files[0].url)
            const f = await ff.blob()
            const csvText = await f.text()
            const jsonData = csvToJson(csvText)

            const columns = Object.keys(jsonData[0]).map(key => (getColumnsGrid(key)));

            setRowsGrid(jsonData);
            setColumnsGrid(columns);

            setOpenModalMicrosoft(false);

            setSelectedPartner("microsoft");

        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            //   setIsUploading(false);
        }
    }, []);

    const handleDrop = React.useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            console.log('file', file);

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                fileMicrosoft.current = {
                    file,
                    name: file.path,
                    path: file.path,
                    preview: URL.createObjectURL(file),
                };
                // setValue("image", newFile.preview);
                handleUpload();
            }
        },
        [handleUpload]
    );

    function renderCardsPartners(partner) {
        if (partner.id_partner === "bagy") {
            if (Object.keys(currentProject).includes("bagy_token") && currentProject.bagy_token) {
                return (
                    <Stack key={partner.id_partner} direction="column" spacing={1} sx={{ cursor: "pointer" }} onClick={() => setSelectedPartner(partner)}>
                        <img src={partner.image} alt={partner.name} style={{ height: 250, width: 430, borderRadius: 10, objectFit: "cover" }} />
                        <Typography variant="h6">{partner.name}</Typography>
                    </Stack>
                )
            }
            return (
                <Stack key={partner.id_partner} direction="column" spacing={1} sx={{ cursor: "default" }}>
                    <div style={{ position: 'relative', height: 250, width: 430 }}>
                        <img src={partner.image} alt={partner.name} style={{ height: '100%', width: '100%', borderRadius: 10, objectFit: "cover" }} />
                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 10 }}></div>
                    </div>
                    <Button variant="outlined" onClick={() => router.push('/dashboard/configuration')}>Configurar Bagy</Button>
                </Stack>
            )
        }
        return (
            <Stack key={partner.id_partner} direction="column" spacing={1} sx={{ cursor: "pointer" }} onClick={() => {
                setOpenModalMicrosoft(true)
            }}>
                <img src={partner.image} alt={partner.name} style={{ height: 250, width: 430, borderRadius: 10, objectFit: "cover" }} />
                <Typography variant="h6">{partner.name}</Typography>
            </Stack>
        )
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            mb: { xs: 3, md: 5 },
                        }}
                    >
                        <Typography variant="h4">Mensagem em massa</Typography>
                    </Stack>
                    {
                        loadingRequest && (
                            <LinearProgress variant="determinate" value={progressBarSize} style={{ marginBottom: 10, width: "100%" }} />
                        )
                    }
                    {
                        currentProject?.token_meta ? (
                            <>
                                {
                                    !selectedPartner ? (
                                        <Stack direction="row" spacing={1}>
                                            {
                                                listPartners.map((partner) => (
                                                    renderCardsPartners(partner)
                                                ))
                                            }
                                        </Stack>
                                    ) : (
                                        <>
                                            <Grid container style={{ justifyContent: "space-evenly" }}>
                                                <Grid xs={12} md={6} lg={8.9}>
                                                    <Stack xs={12} sm={12} md={12} lg={12} gap={1}>
                                                        <Card>
                                                            <Stack spacing={3} sx={{ p: 3 }} direction="row">
                                                                <RHFTextField name="name_campaing" label="Nome da campanha" style={{ width: 300 }} />
                                                                <RHFAutocomplete
                                                                    name="select_account"
                                                                    label="Selecione a conta"
                                                                    placeholder="Selecione a conta"
                                                                    freeSolo
                                                                    disableCloseOnSelect
                                                                    style={{ width: 300 }}
                                                                    options={currentProject?.phones_number?.map((phone) => phone.phone_number) || []}
                                                                    getOptionLabel={(option) => option}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} key={option}>
                                                                            {option}
                                                                        </li>
                                                                    )}
                                                                    renderTags={(selected, getTagProps) =>
                                                                        selected.map((option, index) => (
                                                                            <Chip
                                                                                {...getTagProps({ index })}
                                                                                key={option}
                                                                                label={option}
                                                                                size="small"
                                                                                color="info"
                                                                                variant="soft"
                                                                            />
                                                                        ))
                                                                    }
                                                                />
                                                                <RHFAutocomplete
                                                                    name="select_template"
                                                                    label="Selecione o template"
                                                                    placeholder="Selecione o template"
                                                                    freeSolo
                                                                    loading={templates.length === 0}
                                                                    disableCloseOnSelect
                                                                    style={{ width: 300 }}
                                                                    options={templates}
                                                                    getOptionLabel={(option) => option}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} key={option}>
                                                                            {option}
                                                                        </li>
                                                                    )}
                                                                    renderTags={(selected, getTagProps) =>
                                                                        selected.map((option, index) => (
                                                                            <Chip
                                                                                {...getTagProps({ index })}
                                                                                key={option}
                                                                                label={option}
                                                                                size="small"
                                                                                color="info"
                                                                                variant="soft"
                                                                            />
                                                                        ))
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Card>
                                                        <Card>
                                                            <div style={{ height: (window.screen.height - 550), width: '100%' }}>
                                                                <DataGridPro
                                                                    rows={applyFilter(rowsGrid)}
                                                                    getRowId={(row) => row.id || row[columnsGrid[0]?.field]}
                                                                    columns={columnsGrid}
                                                                    pageSize={100}
                                                                    pagination
                                                                    filterDebounceMs={3000}
                                                                    loading={rowsGrid.length === 0}
                                                                    filterMode='client'
                                                                    filterModel={filterModel}
                                                                    onFilterModelChange={(model) => {
                                                                        setFilterModel(model);
                                                                    }}
                                                                    localeText={{
                                                                        toolbarFiltersTooltipHide: 'Esconder filtros',
                                                                        toolbarFiltersTooltipShow: 'Mostrar filtros',
                                                                        toolbarFiltersTooltipActive: (count) => `${count} filtro(s) ativo(s)`,
                                                                        columnsFiltersTooltipActive: (count) => `${count} filtro(s) ativo(s)`,
                                                                        filterOperatorAfter: 'Depois',
                                                                        filterOperatorBefore: 'Antes',
                                                                        filterOperatorContains: 'Contém',
                                                                        filterOperatorStartsWith: 'Começa com',
                                                                        filterOperatorEndsWith: 'Termina com',
                                                                        filterOperatorIs: 'É',
                                                                        filterOperatorNot: 'Não é',
                                                                        filterOperatorOnOrAfter: 'Em ou após',
                                                                        filterOperatorOnOrBefore: 'Em ou antes',
                                                                        filterOperatorAfterOrOn: 'Depois ou em',
                                                                        filterOperatorBeforeOrOn: 'Antes ou em',
                                                                        filterOperatorEquals: 'Igual a',
                                                                        filterOperatorNotEquals: 'Diferente de',
                                                                        filterOperatorFrom: 'Inicio', // aqui
                                                                        filterOperatorTo: 'Fim' // e aqui
                                                                    }}
                                                                />
                                                            </div>
                                                        </Card>
                                                    </Stack>
                                                </Grid>
                                                <Grid xs={12} md={6} lg={3} style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                    <Card style={{ flex: 3, display: "flex", flexDirection: "column" }}>
                                                        <Stack style={{ height: "auto", flex: 1, width: 280, alignSelf: "center" }}>
                                                            <IphoneComponentOnlyCard
                                                                url={completeTemplate.find(f => f.name === methods.watch("select_template", ""))?.components[0].example}
                                                                subtitle={completeTemplate.find(f => f.name === methods.watch("select_template", ""))?.components[1].example}
                                                                titleButtonToAction={completeTemplate.find(f => f.name === methods.watch("select_template", ""))?.components[2].example}
                                                                buttonToAction={completeTemplate.find(f => f.name === methods.watch("select_template", ""))?.components[2].example}
                                                                foot="Mios Academy"
                                                            />
                                                        </Stack>
                                                        <Stack style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                            <LoadingButton
                                                                variant="contained"
                                                                color="primary"
                                                                type='button'
                                                                onClick={() => setOpenModalSendBulkMessage(true)}
                                                                loading={loadingRequest}
                                                                style={{ marginBottom: 5, width: "90%" }}
                                                            >
                                                                Disparar mensagens {applyFilter(rowsGrid)?.length}
                                                            </LoadingButton>
                                                        </Stack>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                                <Dialog open={openMotalMicrosoft}>
                                    <DialogTitle>Selecione o arquivo</DialogTitle>

                                    <DialogContent>
                                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '52vh' }} spacing={5}>
                                            <Typography variant="body2" color="textSecondary">Selecione o arquivo que contem os dados de contatos.</Typography>
                                            <RHFUploadAvatar
                                                name="fileMicrosoft"
                                                accept={['.csv', '.xls', '.xlsx']}
                                                file={fileMicrosoft.current?.preview}
                                                maxSize={10485760}
                                                onDrop={handleDrop}
                                                helperText={
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            mt: 3,
                                                            mx: 'auto',
                                                            display: 'block',
                                                            textAlign: 'center',
                                                            color: 'text.disabled',
                                                        }}
                                                    >
                                                        Extensões permitidas *.csv, *.xls, *.xlsx
                                                        <br /> tamanho max de {fData(10485760)}
                                                    </Typography>
                                                }
                                            />
                                        </Stack>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setOpenModalMicrosoft(false)}>Cancelar</Button>
                                        <Button onClick={() => setOpenModalMicrosoft(false)}>Criar</Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={openModalSendBulkMessage}>
                                    <DialogTitle>Quase lá</DialogTitle>

                                    <DialogContent>
                                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{}} spacing={5}>
                                            <Typography variant="body2" color="textSecondary">Selecione os campos que contém o telefone e o nome.</Typography>
                                            <Stack spacing={3}>
                                                <Typography variant="span" color="textSecondary" fontSize={12} marginBottom={-2} marginLeft={.4}>Opcional</Typography>
                                                <RHFAutocomplete
                                                    name="select_where_name"
                                                    label="Qual o campo que contém o nome"
                                                    placeholder="Qual o campo que contém o nome"
                                                    freeSolo
                                                    disableCloseOnSelect
                                                    style={{ width: 300 }}
                                                    options={columnsGrid.map((column) => getColumnsGrid(column.field).headerName) || []}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option}>
                                                            {option}
                                                        </li>
                                                    )}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => (
                                                            <Chip
                                                                {...getTagProps({ index })}
                                                                key={option}
                                                                label={option}
                                                                size="small"
                                                                color="info"
                                                                variant="soft"
                                                            />
                                                        ))
                                                    }
                                                />
                                                <RHFAutocomplete
                                                    name="select_where_phone"
                                                    label="Qual o campo que contém o telefone"
                                                    placeholder="Qual o campo que contém o telefone"
                                                    freeSolo
                                                    disableCloseOnSelect
                                                    style={{ width: 300 }}
                                                    options={columnsGrid.map((column) => getColumnsGrid(column.field).headerName) || []}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option}>
                                                            {option}
                                                        </li>
                                                    )}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => (
                                                            <Chip
                                                                {...getTagProps({ index })}
                                                                key={option}
                                                                label={option}
                                                                size="small"
                                                                color="info"
                                                                variant="soft"
                                                            />
                                                        ))
                                                    }
                                                />
                                                <Stack sx={{ width: 300 }}>
                                                    <BankingInviteFriends
                                                        price={applyFilter(rowsGrid)?.length}
                                                        title="Total de "
                                                        description={`Leads irão receber a mensagem. \n Lembre-se que pode ter uma demora de até 10 minutos para o envio de todas as mensagens.`}
                                                        img="/assets/illustrations/characters/character_11.png"
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setOpenModalSendBulkMessage(false)}>Voltar</Button>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            type='button'
                                            loading={loadingRequest}
                                            style={{ marginBottom: 5, width: 190 }}
                                            onClick={() => onSubmit()}
                                        >
                                            Enviar para {applyFilter(rowsGrid)?.length} leads
                                        </LoadingButton>
                                    </DialogActions>
                                </Dialog>

                            </>
                        ) : (
                            <>
                                <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '72vh' }} spacing={5}>
                                    <Typography variant="h5">Nenhuma conta Whatsapp Business configurada</Typography>
                                    <Typography variant="body2" color="textSecondary">Você precisa ter uma conta do WhatsApp Business configurada para ter acesso a essa funcionalidade.</Typography>
                                    <Button variant="contained" onClick={() => router.push('/dashboard/configuration')}>Configurar</Button>
                                </Stack>
                            </>
                        )
                    }
                </Container>
            </FormProvider>
        </>
    );
}
