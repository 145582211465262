import { paths } from 'src/routes/paths';

import { PATH_AFTER_LOGIN } from 'src/config-global';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'MIAI',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  // {
  //   title: 'Depoimentos',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.components,
  // },
  // {
  //   title: 'Ferramentas',
  //   path: '/pages',
  //   icon: <Iconify icon="solar:file-bold-duotone" />,
  // },
  // {
  //   title: 'Teste',
  //   icon: <Iconify icon="solar:notebook-bold-duotone" />,
  //   path: paths.docs,
  // },
];
