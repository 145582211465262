import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { AccountService, OpenAPI } from 'mios-direct-client-module/client-web';

import axios, { endpoints } from 'src/utils/axios';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  currentProject: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'CURRENT_PROJECT') {
    return {
      ...state,
      currentProject: action.payload.currentProject,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        // const response = await axios.get("https://mios-direct.azurewebsites.net/account/me");
        OpenAPI.TOKEN = accessToken;
        const response = await AccountService.getAccountByToken()

        const user = response.data;
        const token = response.token.access_token;

        OpenAPI.TOKEN = token;

        setSession(token);

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              token,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    // const response = await axios.get(`https://mios-direct.azurewebsites.net/account/${encodeURIComponent(email)}/${encodeURIComponent(password)}`);
    // const response = await axios.get(`http://127.0.0.1:8000/account/${encodeURIComponent(email)}/${encodeURIComponent(password)}`);
    const response = await AccountService.getAccountByEmailAndPassword(email, password);

    console.log("RESPONSE", response.token.access_token);

    const token = response?.token?.access_token;
    const user = response.data;

    OpenAPI.TOKEN = token;

    setSession(token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          token,
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (data) => {
    // const response = await axios.post("https://mios-direct.azurewebsites.net/account", data);
    const response = await AccountService.createAccount(data);

    const token = response.token.access_token;
    const user = response.data;

    sessionStorage.setItem(STORAGE_KEY, token);
    setSession(token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          token,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const setCurrentProject = useCallback((currentProject) => {
    dispatch({
      type: 'CURRENT_PROJECT',
      payload: {
        currentProject,
      },
    });
  }, []);

  const reloadInfoUserAndProject = useCallback(async (id_project) => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get("https://mios-direct.azurewebsites.net/account/me");
        // const response = await AccountService.getAccountByToken()

        const user = response.data.data;
        const token = response.data.token.access_token;

        console.log("TESTE", user, id_project);

        const pj = user?.products?.filter(f => f?._id?.$oid === id_project);

        console.log("TESTE RESULT", pj);

        if (pj?.length > 0) {
          dispatch({
            type: 'CURRENT_PROJECT',
            payload: {
              currentProject: pj[0],
            },
          });
        }

        //   setSession(token);

        //   dispatch({
        //     type: 'INITIAL',
        //     payload: {
        //       user: {
        //         ...user,
        //         token,
        //       },
        //     },
        //   });
        // } else {
        //   dispatch({
        //     type: 'INITIAL',
        //     payload: {
        //       user: null,
        //     },
        //   });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      currentProject: state.currentProject,
      //
      login,
      register,
      logout,
      setCurrentProject,
      reloadInfoUserAndProject,
    }),
    [login, logout, register, setCurrentProject, reloadInfoUserAndProject, state.user, status, state.currentProject]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
