import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { height, width } from '@mui/system';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  rocket: icon('ic_rocket-svgrepo-com'),
  configuration: icon('ic_configuration-gear-options-preferences-settings-system-svgrepo-com'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { user, currentProject } = useAuthContext();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      // Menu level 2
      {
        subheader: "Análise de Dados",
        items: [
          {
            title: "Dashboard",
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          currentProject?.bagy_token?.length > 0 && {
            title: "Bagy",
            path: paths.dashboard.purchase_bagy,
            icon: ICONS.dashboard,
            children: [
              // { title: "Visão Geral", path: paths.dashboard.root },
              { title: "Vendas", path: paths.dashboard.purchase_bagy },
              // { title: "Clientes", path: paths.dashboard.root },
              { title: "Produtos", path: paths.dashboard.purchase_bagy_product },
              // { title: "Financeiro", path: paths.dashboard.root },
              // { title: "Relatórios", path: paths.dashboard.root },
            ],
          },
          {
            title: "Benchmarketing",
            path: paths.dashboard.benchmarking,
            icon: ICONS.dashboard,
          },
          {
            title: "SEO",
            path: paths.dashboard.seo,
            icon: ICONS.dashboard,
          }
          // {
          //   title: t('analytics'),
          //   path: paths.dashboard.general.analytics,
          //   icon: ICONS.analytics,
          // },
          // {
          //   title: t('file'),
          //   path: paths.dashboard.general.file,
          //   icon: ICONS.file,
          // }
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: "WHATSAPP",
        items: [
          // USER
          // {
          //   title: t('user'),
          //   path: paths.dashboard.user.root,
          //   icon: ICONS.user,
          //   children: [
          //     // { title: t('profile'), path: paths.dashboard.user.root },
          //     { title: t('cards'), path: paths.dashboard.user.cards },
          //     // { title: t('list'), path: paths.dashboard.user.list },
          //     { title: t('create'), path: paths.dashboard.user.new },
          //     { title: t('edit'), path: paths.dashboard.user.demo.edit },
          //     // { title: t('account'), path: paths.dashboard.user.account },
          //   ],
          // },

          // CHAT

          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },

          // BLOG
          currentProject?.token_meta?.length > 0 && {
            title: t('template'),
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            children: [
              { title: t('list'), path: paths.dashboard.post.root },
              // { title: t('details'), path: paths.dashboard.post.demo.details },
              { title: t('create'), path: paths.dashboard.post.new },
              // { title: t('edit'), path: paths.dashboard.post.demo.edit },
            ],
          },

          // BULK MESSAGE
          {
            title: t('bulk'),
            path: paths.dashboard.bulk.root,
            icon: ICONS.rocket
          },

          // KANBAN
          // {
          //   title: t('kanban'),
          //   path: paths.dashboard.kanban,
          //   icon: ICONS.kanban,
          // },
        ],
      },
      {
        subheader: "Configuração",
        items: [
          // CONFIGURATION
          {
            title: "Configuração",
            path: paths.dashboard.configuration.root,
            icon: ICONS.configuration,
            children: [
              { title: "Integração", path: paths.dashboard.configuration.root },
              // { title: "Usuários", path: paths.dashboard.configuration.users },
            ],
          },
        ]
      },
      // {
      //   subheader: "Clientes",
      //   items: [
      //     // CONFIGURATION
      //     {
      //       title: "Clientes",
      //       path: paths.dashboard.configuration.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "Lista de Clientes", path: paths.dashboard.configuration.root },
      //         { title: "Cadastrar Cliente", path: paths.dashboard.configuration.users },
      //       ],
      //     },
      //   ]
      // },
      // {
      //   subheader: "Financeiro",
      //   items: [
      //     // CONFIGURATION
      //     {
      //       title: "Financeiro",
      //       path: paths.dashboard.configuration.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "Dashboard", path: paths.dashboard.configuration.root },
      //         { title: "Pagamentos", path: paths.dashboard.configuration.users },
      //         { title: "Link de pagamento", path: paths.dashboard.configuration.users },
      //       ],
      //     },
      //   ]
      // },    
      // {
      //   subheader: "Contratos",
      //   items: [
      //     // CONFIGURATION
      //     {
      //       title: "Contratos",
      //       path: paths.dashboard.configuration.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "Emitir contrato", path: paths.dashboard.configuration.root },
      //         { title: "Contratos dos Clientes", path: paths.dashboard.configuration.users },
      //       ],
      //     },
      //   ]
      // },     
      // {
      //   subheader: "Eventos",
      //   items: [
      //     // CONFIGURATION
      //     {
      //       title: "Eventos",
      //       path: paths.dashboard.configuration.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "Cadastrar Evento", path: paths.dashboard.configuration.root },
      //         { title: "PDN Goiânia", path: paths.dashboard.configuration.users },
      //         { title: "PDN São Paulo", path: paths.dashboard.configuration.users },
      //         { title: "Check in / No Show", path: paths.dashboard.configuration.users },
      //       ],
      //     },
      //   ]
      // },      
    ],
    [t, currentProject]
  );

  return data;
}
