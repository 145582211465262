import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { TemplateService, WhatsappMessageClickedService } from "mios-direct-client-module/client-web";
import { useEffect, useRef } from "react";

export default function RedirectPage() {

    // Get the template name from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const template_name_param = urlParams.get('template_name');

    const alreadyClicked = useRef(false);

    useEffect(() => {
        if (alreadyClicked.current) return;
        alreadyClicked.current = true;
        WhatsappMessageClickedService.createWhatsappMessageClicked({
            template_name: template_name_param
        }).finally(() => {
            console.log('WhatsappMessageClickedService.createWhatsappMessageClicked');
            TemplateService.returnTemplateByName(template_name_param).then((response) => {
                console.log('TemplateService.returnTemplateByName');
                console.log(response.data);
                const linkToRedirect = response.data[0]?.components?.find((template) => template.type === "BUTTONS").example;
                console.log("REDIRECT", linkToRedirect);
                window.location.replace(linkToRedirect);
            });
        });
    }, [template_name_param]);

    return (
        <>
            <Stack justifyContent="center" alignItems="center" alignContent="center" height="100%">
                <Typography variant="h5">Carregando...</Typography>
            </Stack>
        </>
    );
}