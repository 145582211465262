import React, { forwardRef } from "react"
import "./iphoneComponentOnlyCard.css"
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';


export default function IphoneComponentOnlyCard({ url, title, subtitle, foot, buttonToAction, titleButtonToAction, stopMarketing }) {
    return (
        <div className="demo__card demo__card--regular">
            <div style={{ backgroundColor: "white", width: "100%", height: "auto", minHeight: 150, borderRadius: 10, padding: 5, gap: 10, display: "flex", flexDirection: "column" }}>
                <div style={{ borderRadius: 6, overflow: "hidden", minHeight: 150, maxHeight: 150 }}>
                    {/* <video src={url} alt="Imagem" style={{ width: "98%", height: 150, margin: "auto", display: "block", objectFit: "cover" }} /> */}
                    {
                        url && (
                            <img src={url} alt="Imagem" style={{ width: "98%", minHeight: 150, maxHeight: 150, margin: "auto", display: "block", objectFit: "cover" }} />
                        )
                    }
                </div>
                {/* <div style={{ width: "94%", alignSelf: "center" }}>
                                    <Typography.Text style={{ fontSize: 12, textAlign: "start", fontWeight: 700 }}>{title}</Typography.Text>
                                </div> */}
                <div style={{ width: "94%", alignSelf: "center" }}>
                    <Typography style={{ fontSize: 12, textAlign: "start" }}>{subtitle}</Typography>
                </div>
                <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "space-between", display: "flex" }}>
                    <Typography style={{ fontSize: 12, textAlign: "start" }}>{foot}</Typography>
                    {
                        url && (
                            <div style={{ minWidth: 40, display: "flex", justifyContent: "center", alignItems: "end" }}>
                                <Typography style={{ fontSize: 12, textAlign: "start" }}>19:00</Typography>
                            </div>
                        )
                    }
                </div>

                {
                    buttonToAction && (
                        <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "center", display: "flex" }}>
                            <div className="button-action">
                                <Typography style={{ fontSize: 12, textAlign: "start", color: "green", fontWeight: 700 }}>{titleButtonToAction}</Typography>
                            </div>
                        </div>
                    )
                }

                {
                    stopMarketing && subtitle && (
                        <div style={{ width: "94%", alignSelf: "center", opacity: .6, justifyContent: "center", display: "flex" }}>
                            <div className="button-action">
                                <Typography style={{ fontSize: 12, textAlign: "start", color: "green", fontWeight: 700 }}>Parar promoções</Typography>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

IphoneComponentOnlyCard.propTypes = {
    url: PropTypes?.string,
    title: PropTypes?.string,
    subtitle: PropTypes?.string,
    foot: PropTypes?.string,
    buttonToAction: PropTypes?.bool,
    titleButtonToAction: PropTypes?.string,
    stopMarketing: PropTypes?.bool
}