import { Helmet } from 'react-helmet-async';
import { BulkMessageView } from 'src/sections/bulk-message';

import { CalendarView } from 'src/sections/calendar/view';

// ----------------------------------------------------------------------

export default function BulkMessagePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Mensagem em massa</title>
      </Helmet>

      <BulkMessageView />
    </>
  );
}
