// Function to parse CSV text to JSON
export function csvToJson(csv) {
    const lines = csv.split('\n');
    const result = [];

    // Get the headers
    // const headers = lines[0].split(',');
    let headers = null;
    if (String(csv).includes(",")){
        headers = lines[0].split(',');  
    }
    if (String(csv).includes(";")){
        headers = lines[0].split(';');  
    }

    // Loop through all the lines except the first (header) line
    for (let i = 1; i < lines.length; i += 1) {
        const obj = {};
        // const currentLine = lines[i].split(',');
        let currentLine = null;
        if (String(csv).includes(",")){
            currentLine = lines[i].split(',');  
        }
        if (String(csv).includes(";")){
            currentLine = lines[i].split(';');  
        }

        // Assign each value to the respective header
        headers.forEach((header, j) => {
            // remove the double quotes and remove any commas inside the do
            obj[header] = currentLine[j].replace(/"/g, '').replace(/,/g, '');
        });
        result.push(obj);
    }

    return result;
}